<template>
  <base-toggle
    v-bind="{ value, disabled }"
    v-model="modelValue"
    data-test-id="vf-toggle-token"
    @change="$emit('change', $event)"
  >
    <span
      class="rounded-full px-4 py-3 text-sm lh-1 ring ring-inset i-flex duration peer-focus-visible:ring-grey-10"
      :class="[
        disabled ? 'c-grey-30 ring-grey-40'
        : selected
          ? 'ring-transparent bg-brand-1 c-white '
          : 'ring-grey-40 hover:ring-grey-10 c-grey-10 bg-white',
      ]"
    >
      <slot />
    </span>
  </base-toggle>
</template>

<script lang="ts" setup>
const props = defineProps<{
  value?: string
  disabled?: boolean
}>()

defineEmits<{ change: [value: boolean] }>()

const modelValue = defineModel<boolean | string[]>()

const selected = computed(() =>
  Array.isArray(modelValue.value) && props.value ? modelValue.value.includes(props.value) : modelValue.value
)
</script>
